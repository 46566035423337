import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';
if (environment.production) {
    enableProdMode();
}

const SENTRY_DSN = 'https://1a858ed6ef4a176fe9d662b4b3efc6f4@sentry.energiesdemain.org/11';
if (SENTRY_DSN.includes('https')) {
    Sentry.init({
        dsn: SENTRY_DSN,
        release: '',
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            'localhost',
            /^https:\/\/recette\.siterre\.fr\/back/,
            /^https:\/\/www\.siterre\.fr\/back/,
            /^https:\/\/recette\.siterre\.fr\/geo/,
            /^https:\/\/www\.siterre\.fr\/geo/,
            /^http:\/\/localhost\//,
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
