import { Inject, Injectable } from '@angular/core';
import * as d3 from 'd3';

import { Indicator } from 'src/app/models/Indicator';

import { UsefulService } from 'src/app/services/UsefulService';
import { StatsService } from 'src/app/services/StatsService';

@Injectable({
    providedIn: 'root',
})
export class MeanDistribution {
    public classCountsAvailable = [2, 4, 8];

    public id = 'moyenne';
    public label = 'Moyennes emboitées';

    constructor(
        @Inject(StatsService) private statsService: StatsService,
        @Inject(UsefulService) private usefulService: UsefulService,
    ) {}

    setClassCount(indicatorPlot: Indicator) {
        const distincValues = new Set(indicatorPlot.values);
        const classCountsAvailable = this.classCountsAvailable.filter(
            (count) => count <= indicatorPlot.classCount,
        );

        indicatorPlot.classCount = Math.max(
            ...classCountsAvailable.filter((count) => count <= distincValues.size),
        );
    }

    setScale(indicatorPlot: Indicator) {
        let values = indicatorPlot.values;
        if (indicatorPlot.separate_zero_in_lgd) {
            values = values.filter((value) => value != 0);
        }

        const domain = this.statsService.nestedMeans(values, indicatorPlot.classCount);
        const range = d3.range(indicatorPlot.classCount + 1);
        //  If the number of values in the scale’s range is n + 1, the number of values in the scale’s domain must be n
        // https://d3js.org/d3-scale/threshold
        indicatorPlot.valueScale = d3.scaleThreshold().domain(domain).range(range);
    }

    setLegendBoundaries(indicatorPlot: Indicator) {
        const decimalCount = indicatorPlot.decimalCount;

        const legendBoundaries = [];
        const step = 1 / Math.pow(10, decimalCount);

        // first boundaries are set for -infinity to first value
        // last boundaries are set for last value to +infinity
        // so we skip those ones, the loop start to 1
        for (let i = 1; i < indicatorPlot.classCount + 1; i++) {
            const limits = [...indicatorPlot.valueScale.invertExtent(i)];

            let boundaryInf: number;
            let boundarySup: number;

            if (i == 1) {
                boundaryInf = this.usefulService.floor(limits[0], decimalCount);
                boundarySup = this.usefulService.round(limits[1], decimalCount);
            } else if (i == indicatorPlot.classCount) {
                const valueInf = this.usefulService.round(limits[0], decimalCount) + step;
                boundaryInf = this.usefulService.round(valueInf, decimalCount); // rounding to avoid precision error;
                boundarySup = this.usefulService.ceil(limits[1], decimalCount);
            } else {
                const valueInf = this.usefulService.round(limits[0], decimalCount) + step;
                boundaryInf = this.usefulService.round(valueInf, decimalCount); // rounding to avoid precision error;
                boundarySup = this.usefulService.round(limits[1], decimalCount);
            }
            const boundaries = [boundaryInf, boundarySup];
            legendBoundaries.push(boundaries);
        }
        return legendBoundaries;
    }
}
