import { Injectable } from '@angular/core';
import { Color, Palette, RGB } from '../models/ColorTypes';

@Injectable({
    providedIn: 'root',
})
export class ColorService {
    private paletteCatalog: { [paletteId: string]: Palette } = {
        RedYelGrn: {
            initial: '#FF0000',
            center: '#FFFF00',
            final: '#00B050',
        },
        GrnYelRed: {
            initial: '#00B050',
            center: '#FFFF00',
            final: '#FF0000',
        },
        GrnYel: {
            initial: '#00823B',
            center: '#66D030',
            final: '#FFFF00',
        },
        YelGrn: {
            final: '#00823B',
            center: '#66D030',
            initial: '#FFFF00',
        },
        vioVio: {
            initial: '#40203C',
            center: '#B265A9',
            final: '#E7CFE4',
        },
        Viovio: {
            final: '#40203C',
            center: '#B265A9',
            initial: '#E7CFE4',
        },
        orgOrg: {
            initial: '#6E2606',
            center: '#F36522',
            final: '#FDCD3F',
        },
        Orgorg: {
            final: '#6E2606',
            center: '#F36522',
            initial: '#FDCD3F',
        },
        grnGrn: {
            initial: '#3C4810',
            center: '#B0D136',
            final: '#DCEBA7',
        },
        Grngrn: {
            final: '#3C4810',
            center: '#B0D136',
            initial: '#DCEBA7',
        },
        bleuBleu: {
            initial: '#003958',
            center: '#00B0F0',
            final: '#A7E8FF',
        },
        Bleubleu: {
            final: '#003958',
            center: '#00B0F0',
            initial: '#A7E8FF',
        },
        redRed: {
            initial: '#760000',
            center: '#FF0000',
            final: '#FFC5C5',
        },
        Redred: {
            final: '#760000',
            center: '#FF0000',
            initial: '#FFC5C5',
        },
        GreyGrey: {
            final: '#868686',
            center: '#d1d1d1',
            initial: '#f1f1f1',
        },
        GreyGreyR: {
            final: '#f1f1f1',
            center: '#d1d1d1',
            initial: '#868686',
        },
        BlackWhite: {
            final: '#000000',
            center: '#aaaaaa',
            initial: '#ffffff',
        },
        WhiteBlack: {
            final: '#ffffff',
            center: '#aaaaaa',
            initial: '#000000',
        },
        YelBleuBleu: {
            initial: '#FFFF00',
            center: '#0FC0FF',
            final: '#003958',
        },
        BleuBleuYel: {
            final: '#FFFF00',
            center: '#0FC0FF',
            initial: '#003958',
        },
        BleuYelOrg: {
            initial: '#0FC0FF',
            center: '#FFFF00',
            final: '#F36522',
        },
        OrgYelBleu: {
            final: '#0FC0FF',
            center: '#FFFF00',
            initial: '#F36522',
        },
        BenTo: {
            initial: '#1976D2',
            center: '#B9F2FF',
            final: '#353F45',
        },
        ToBen: {
            final: '#1976D2',
            center: '#B9F2FF',
            initial: '#353F45',
        },
        GreBlePurp: {
            initial: '#004439',
            center: '#B9F2FF',
            final: '#3F007D',
        },
        PurpBleGre: {
            final: '#004439',
            center: '#B9F2FF',
            initial: '#004439',
        },
        BleuGrnOrg: {
            initial: '#1976D2',
            center: '#C2EC13',
            final: '#FF9900',
        },
        OrgGrnBleu: {
            final: '#1976D2',
            center: '#C2EC13',
            initial: '#FF9900',
        },
        GrnBleuOrg: {
            initial: '#FF9900',
            center: '#B9F2FF',
            final: '#004439',
        },
        OrgBleuGrn: {
            final: '#FF9900',
            center: '#B9F2FF',
            initial: '#004439',
        },
        DarkblueYelRed: {
            final: '#EB1313',
            center: '#FFFFBF',
            initial: '#0000BF',
        },
        RedYelDarkblue: {
            final: '#0000BF',
            center: '#FFFFBF',
            initial: '#EB1313',
        },
        lightblueWhiteRed: {
            final: '#EB1313',
            center: '#F2F2F2',
            initial: '#0FC0FF',
        },
        RedWhitelightblue: {
            final: '#0FC0FF',
            center: '#F2F2F2',
            initial: '#EB1313',
        },
        orangeToBlue: {
            final: '#004585',
            center: '#6fbda5',
            initial: '#db3700',
        },
        blueToOrange: {
            final: '#db3700',
            center: '#6fbda5',
            initial: '#004585',
        },
        whiteToOrange: {
            final: '#751000',
            center: '#ff5e00',
            initial: '#f1ddda',
        },
        whiteToBlue: {
            final: '#00214d',
            center: '#0097a8',
            initial: '#c0d9dd',
        },
        OrGrBlue: {
            final: '#f27660',
            center: '#f2b560',
            initial: '#5bc0de',
        },
        OrGrBlue2: {
            final: '#f27660',
            center: '#f2b560',
            initial: '#1ec7b9',
        },
    };

    public colorCatalog: Array<Color> = [
        {
            id: 1,
            type: 'mono',
            name: 'vioVio',
        },
        {
            id: 2,
            type: 'mono',
            name: 'Viovio',
        },
        {
            id: 3,
            type: 'mono',
            name: 'orgOrg',
        },
        {
            id: 4,
            type: 'mono',
            name: 'Orgorg',
        },
        {
            id: 5,
            type: 'mono',
            name: 'grnGrn',
        },
        {
            id: 6,
            type: 'mono',
            name: 'Grngrn',
        },
        {
            id: 7,
            type: 'mono',
            name: 'bleuBleu',
        },
        {
            id: 8,
            type: 'mono',
            name: 'Bleubleu',
        },
        {
            id: 9,
            type: 'mono',
            name: 'redRed',
        },
        {
            id: 10,
            type: 'mono',
            name: 'Redred',
        },
        {
            id: 11,
            type: 'bi',
            name: 'RedYelGrn',
        },
        {
            id: 12,
            type: 'bi',
            name: 'GrnYelRed',
        },
        {
            id: 13,
            type: 'bi',
            name: 'GrnYel',
        },
        {
            id: 14,
            type: 'bi',
            name: 'YelGrn',
        },
        {
            id: 15,
            type: 'bi',
            name: 'YelBleuBleu',
        },
        {
            id: 16,
            type: 'bi',
            name: 'BleuBleuYel',
        },
        {
            id: 17,
            type: 'bi',
            name: 'BleuYelOrg',
        },
        {
            id: 18,
            type: 'bi',
            name: 'OrgYelBleu',
        },
        {
            id: 19,
            type: 'bi',
            name: 'BenTo',
        },
        {
            id: 20,
            type: 'bi',
            name: 'ToBen',
        },
        {
            id: 21,
            type: 'bi',
            name: 'GreBlePurp',
        },
        {
            id: 22,
            type: 'bi',
            name: 'PurpBleGre',
        },
        {
            id: 23,
            type: 'bi',
            name: 'BleuGrnOrg',
        },
        {
            id: 24,
            type: 'bi',
            name: 'OrgGrnBleu',
        },
        {
            id: 25,
            type: 'bi',
            name: 'GrnBleuOrg',
        },
        {
            id: 26,
            type: 'bi',
            name: 'OrgBleuGrn',
        },
        {
            id: 27,
            type: 'bi',
            name: 'RedYelDarkblue',
        },
        {
            id: 28,
            type: 'bi',
            name: 'DarkblueYelRed',
        },
        {
            id: 29,
            type: 'bi',
            name: 'lightblueWhiteRed',
        },
        {
            id: 30,
            type: 'bi',
            name: 'RedWhitelightblue',
        },
        {
            id: 31,
            type: 'bi',
            name: 'orangeToBlue',
        },
        {
            id: 32,
            type: 'bi',
            name: 'blueToOrange',
        },
        {
            id: 33,
            type: 'mono',
            name: 'whiteToOrange',
        },
        {
            id: 34,
            type: 'mono',
            name: 'whiteToBlue',
        },
        {
            id: 35,
            type: 'bi',
            name: 'OrGrBlue',
        },
        {
            id: 36,
            type: 'bi',
            name: 'OrGrBlue2',
        },
        {
            id: 37,
            type: 'mono',
            name: 'GreyGrey',
        },
        {
            id: 38,
            type: 'mono',
            name: 'GreyGreyR',
        },
        {
            id: 39,
            type: 'mono',
            name: 'WhiteBlack',
        },
        {
            id: 40,
            type: 'mono',
            name: 'BlackWhite',
        },
    ];

    getColorPalette(name: string, classNumber: number) {
        if (name === 'random') {
            return new Array(classNumber).fill(null).map(
                () =>
                    '#' +
                    Math.floor(Math.random() * 16777215)
                        .toString(16)
                        .padStart(6, '0'),
            );
        } else if (!this.paletteCatalog[name]) {
            return JSON.parse(name);
        } else {
            const paletteToReturn = this.paletteCatalog[name];
            const colorsNb = paletteToReturn.center ? 'triColor' : 'biColor';
            return this.buildColorPalette(classNumber, colorsNb, paletteToReturn);
        }
    }

    getGradientId(name: string) {
        const gradient = this.colorCatalog.find((color: Color) => color.name === name);
        return gradient ? gradient.id : 1;
    }

    private generateColor(initialHex: string, finalHex: string, coeff: number) {
        const initialRGB = this.rgbFromHex(initialHex);
        const finalRGB = this.rgbFromHex(finalHex);
        const newColor = { ...initialRGB };

        for (const param in newColor) {
            newColor[param] += coeff * (finalRGB[param] - initialRGB[param]);
        }
        return this.hexFromRgb(newColor);
    }

    private buildColorPalette(nbClass: number, palettetype: string, palette: Palette): string[] {
        let newPalette: Array<string> = [];

        if (palettetype == 'biColor') {
            for (let i = 0; i < nbClass; i++) {
                const coeff = i / (nbClass - 1);
                const newColor = this.generateColor(palette.initial, palette.final, coeff);
                newPalette.push(newColor);
            }
        } else {
            if ((nbClass - 1) / 2 == Math.floor((nbClass - 1) / 2)) {
                //Cas ou la couleur du milieu correspond à la classe du milieu

                for (let i = 0; i < (nbClass - 1) / 2 + 1; i++) {
                    const coeff = i / ((nbClass - 1) / 2);
                    const newColor = this.generateColor(palette.initial, palette.center, coeff);
                    newPalette.push(newColor);
                }

                for (let i = 1; i < (nbClass - 1) / 2 + 1; i++) {
                    const coeff = i / ((nbClass - 1) / 2);
                    const newColor = this.generateColor(palette.center, palette.final, coeff);
                    newPalette.push(newColor);
                }
            } else {
                //Cas ou la couleur du milieu ne peut pas rentrer dans une classe.
                let i = 0;
                while (i < (nbClass - 1) / 2) {
                    const coeff = i / (nbClass - 1);
                    const newColor = this.generateColor(palette.initial, palette.final, coeff);
                    newPalette.push(newColor);
                    i++;
                }

                i = 0.5;
                while (i < (nbClass - 1) / 2 + 1) {
                    const coeff = i / ((nbClass - 1) / 2);
                    const newColor = this.generateColor(palette.center, palette.final, coeff);
                    newPalette.push(newColor);
                    i++;
                }
            }
        }

        // Dans cas ou l'on a qu'une classe
        if (nbClass == 1) {
            newPalette = [palette.initial];
        }

        return newPalette;
    }

    private hexFromRgb(RGB: { r: number; g: number; b: number }) {
        const ToHex = (VFloat: number) => {
            let rslt = Math.round(VFloat).toString(16);
            if (rslt.length === 1) {
                rslt = '0' + rslt;
            }
            return rslt;
        };

        try {
            return '#' + ToHex(RGB.r) + ToHex(RGB.g) + ToHex(RGB.b);
        } catch (err) {
            console.log('erreur conversion to hex !');
            console.log(RGB);
            return '#999999';
        }
    }

    private rgbFromHex(hexa: string) {
        const rgb: RGB = {
            r: parseInt(hexa.slice(1, 3), 16),
            g: parseInt(hexa.slice(3, 5), 16),
            b: parseInt(hexa.slice(5), 16),
        };
        return rgb;
    }
}
