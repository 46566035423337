import { Inject, Injectable } from '@angular/core';

import { Indicator } from 'src/app/models/Indicator';

import { ColorService } from 'src/app/services/ColorService';
import { UsefulService } from 'src/app/services/UsefulService';
import { LinearDistribution } from 'src/app/services/plotIndicator/distributions/methods/Linear';
import { ManualDistribution } from 'src/app/services/plotIndicator/distributions/methods/Manual';
import { MeanDistribution } from 'src/app/services/plotIndicator/distributions/methods/Mean';
import { NaturalBreaksDistribution } from 'src/app/services/plotIndicator/distributions/methods/NaturalBreaks';
import { QuantileDistribution } from 'src/app/services/plotIndicator/distributions/methods/Quantile';
import { StandardDeviationDistribution } from 'src/app/services/plotIndicator/distributions/methods/StandardDeviation';

@Injectable({
    providedIn: 'root',
})
export class DistributionValueService {
    public methodsInfo = [
        {
            id: 'linéaire',
            label: 'Linéaire',
        },
        {
            id: 'quantile',
            label: 'Quantile',
        },
        {
            id: 'moyenne',
            label: 'Moyennes emboitées',
        },
        {
            id: 'standardDeviation',
            label: 'Ecarts types',
        },
        {
            id: 'naturalBreaks',
            label: 'Seuils naturels',
        },
        {
            id: 'manuel',
            label: 'Seuils manuels',
        },
    ];

    constructor(
        @Inject(ColorService) private colorService: ColorService,
        @Inject(UsefulService) private usefulService: UsefulService,
        @Inject(LinearDistribution) private linearDistribution: LinearDistribution,
        @Inject(ManualDistribution) private manualDistribution: ManualDistribution,
        @Inject(MeanDistribution) private meanDistribution: MeanDistribution,
        @Inject(NaturalBreaksDistribution)
        private naturalBreaksDistribution: NaturalBreaksDistribution,
        @Inject(QuantileDistribution) private quantileDistribution: QuantileDistribution,
        @Inject(StandardDeviationDistribution)
        private standardDeviationDistribution: StandardDeviationDistribution,
    ) {}

    run(indicatorPlot: any, refreshIntervals?: boolean) {
        this.setValues(indicatorPlot);

        const methodService = this.setMethodService(indicatorPlot);
        this.setClassCountsAvailable(methodService, indicatorPlot);

        if (refreshIntervals) {
            methodService.setClassCount(indicatorPlot);
            methodService.setScale(indicatorPlot);
            const legendBoundaries = methodService.setLegendBoundaries(indicatorPlot);
            indicatorPlot.legendBoundaries = legendBoundaries;
        }
        const isManual = methodService.id == 'manuel';
        if (indicatorPlot.legendBoundaries.length && !isManual) {
            this.setMin(indicatorPlot);
            this.setMax(indicatorPlot);
        }

        return indicatorPlot.legendBoundaries;
    }

    init(indicatorPlot: Indicator) {
        this.cleanLineForm(indicatorPlot);

        let method: { id: string; label: string };
        try {
            method = this.methodsInfo.find((method) => method.id == 'manuel');
            const tableBornes = JSON.parse(indicatorPlot.distributionMethodId);
            const decimalCount = indicatorPlot.decimalCount;

            indicatorPlot.legendBoundaries =
                this.manualDistribution.convertTableBornesToLegendBoundaries(
                    tableBornes,
                    decimalCount,
                );
        } catch (error) {
            method = this.methodsInfo.find(
                (method) => method.id == indicatorPlot.distributionMethodId,
            );
        }

        indicatorPlot.distribution = {
            type: 'value',
            method: method,
        };
    }

    setValues(indicatorPlot: Indicator): number[] {
        const values = indicatorPlot.geojson.features
            .filter((feature) => {
                const value = feature.properties.value;
                const isNumber = typeof value === 'number';
                const isNotZero = value !== 0 || !indicatorPlot.separate_zero_in_lgd;
                const isNotNull =
                    (value !== undefined && value !== null) || !indicatorPlot.exclude_null;
                return isNumber && isNotZero && isNotNull;
            })
            .map((feature) => feature.properties.value);

        indicatorPlot.values = values.sort((a, b) => a - b);
        return values;
    }

    setMethodService(indicatorPlot: Indicator, methodId?: string) {
        methodId = methodId ? methodId : indicatorPlot.distribution.method.id;

        let methodService: any;
        if (methodId == 'linéaire') {
            methodService = this.linearDistribution;
        } else if (methodId == 'manuel') {
            methodService = this.manualDistribution;
        } else if (methodId == 'moyenne') {
            methodService = this.meanDistribution;
        } else if (methodId == 'naturalBreaks') {
            methodService = this.naturalBreaksDistribution;
        } else if (methodId == 'quantile') {
            methodService = this.quantileDistribution;
        } else if (methodId == 'standardDeviation') {
            methodService = this.standardDeviationDistribution;
        } else {
            const message = `Distribution method is not understood: ${methodId}`;
            console.error(message);
            throw Error(message);
        }

        return methodService;
    }

    setClassCountsAvailable(methodService: any, indicatorPlot: Indicator) {
        const distincValues = new Set(indicatorPlot.values);
        let classCountsAvailable: number[];

        const isChart = indicatorPlot.form == 'chart';
        const isManual = methodService.id == 'manuel';

        if (isChart) {
            classCountsAvailable = [indicatorPlot.classCount];
        } else {
            classCountsAvailable = methodService.classCountsAvailable;
            if (!isManual) {
                classCountsAvailable = classCountsAvailable.filter(
                    (count: number) => count <= distincValues.size,
                );
                if (
                    indicatorPlot.isFiltered &&
                    !classCountsAvailable.includes(indicatorPlot.classCount)
                ) {
                    classCountsAvailable.push(indicatorPlot.classCount);
                }
            }
        }

        indicatorPlot.classCountsAvailable = classCountsAvailable.sort();
    }

    setMin(indicatorPlot: Indicator) {
        const legendBoundaries = indicatorPlot.legendBoundaries;
        const features = indicatorPlot.geojson.features.filter(
            (feature: GeoJSON.Feature) => ![undefined, null].includes(feature.properties.value),
        );
        const minimum = features.reduce(
            (min: number, feature: GeoJSON.Feature) => Math.min(min, feature.properties.value),
            legendBoundaries[0][0],
        );
        legendBoundaries[0][0] = this.usefulService.floor(minimum, indicatorPlot.decimalCount);
    }

    setMax(indicatorPlot: Indicator) {
        const legendBoundaries = indicatorPlot.legendBoundaries;
        const features = indicatorPlot.geojson.features.filter(
            (feature: GeoJSON.Feature) => ![undefined, null].includes(feature.properties.value),
        );
        const lastIndex = legendBoundaries.length - 1;
        const maximum = features.reduce(
            (max: number, feature: GeoJSON.Feature) => Math.max(max, feature.properties.value),
            legendBoundaries[lastIndex][1],
        );
        const maxCeiled = this.usefulService.ceil(maximum, indicatorPlot.decimalCount);
        legendBoundaries[lastIndex][1] = maxCeiled;

        legendBoundaries.forEach((boundaries) => {
            boundaries[0] = Math.min(boundaries[0], maxCeiled);
            boundaries[1] = Math.min(boundaries[1], maxCeiled);
        });
    }

    setColors(indicatorPlot: Indicator): string[] {
        const degrade = indicatorPlot.newColorGradient
            ? indicatorPlot.newColorGradient
            : indicatorPlot.degrade;

        // override degrade with its new values (eventually) then remove newColorGradient
        indicatorPlot.degrade = degrade;
        delete indicatorPlot.newColorGradient;

        let colorPalet = this.colorService.getColorPalette(degrade, indicatorPlot.classCount);

        // if (indicatorPlot.form === 'point_proportional') {
        //     colorTable = colorTable.map((c: string) => colorTable[0]);
        // }

        const colorTable = [];

        for (let i = 0; i < indicatorPlot.classCount; i++) {
            colorTable[i] = colorPalet[i];
        }

        indicatorPlot.tableCouleurs = colorTable;
        return colorTable;
    }

    setLegend(indicatorPlot: Indicator, refreshIntervals?: boolean) {
        const colorTable = this.setColors(indicatorPlot);
        const legendBoundaries = indicatorPlot.legendBoundaries;
        const dashArray = indicatorPlot.dashArray;

        let legends = [];
        for (let i = 0; i < indicatorPlot.classCount; i++) {
            let label: string;
            let min: number;
            let max: number;

            if (indicatorPlot.distribution.method.id == 'manuel') {
                const manualLegend = this.manualDistribution.setLegendLabel(i, legendBoundaries);
                label = manualLegend.label;
                min = manualLegend.min;
                max = manualLegend.max;
                legendBoundaries[i][0] = min;
                legendBoundaries[i][1] = max;
            } else {
                min = legendBoundaries[i][0];
                max = legendBoundaries[i][1];
                const minStringified = this.usefulService.stringifyNumber(min);
                const maxStringified = this.usefulService.stringifyNumber(max);
                if (min == max) {
                    label = minStringified;
                } else {
                    label = minStringified + ' à ' + maxStringified;
                }
            }

            legends.push({
                id: i,
                min: min,
                max: max,
                lib: label,
                color: colorTable[i],
                default_radius_weight: 1,
                default_contouropacity: 1,
                default_fillopacity: 1,
                dashArray: dashArray,
            });
        }

        if (indicatorPlot.form === 'point_proportional') {
            const defaultRadius = indicatorPlot.default_radius_weight || 1;
            const weight = indicatorPlot.weightMultiplier || 1;
            legends.forEach(
                (legend, index) =>
                    (legend.default_radius_weight = (index + 1) * defaultRadius * weight),
            );
        }

        indicatorPlot.legende = legends;
    }

    cleanLineForm(indicatorPlot: any) {
        if (indicatorPlot.form === 'line' && typeof indicatorPlot.dashArray === 'string') {
            indicatorPlot.dashArray = indicatorPlot.dashArray
                .split(',')
                .map((x: string) => Number(x));
        }
    }

    getLegendBoundaryIndex(indicatorPlot: Indicator, value: number) {
        const legendBoundaries = indicatorPlot.legendBoundaries;
        const decimalCount = indicatorPlot.decimalCount;
        const roundedValue = this.usefulService.round(value, decimalCount);

        const boundaries = legendBoundaries.find(
            (boundaries) => roundedValue >= boundaries[0] && roundedValue <= boundaries[1],
        );
        const index = legendBoundaries.indexOf(boundaries);
        return index;
    }
}
